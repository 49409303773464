<template>
  <v-btn
    color="primary"
    large
    :to="$_getRouteRestore(role,model.key,$route.params.id,me)"
  >
  Restore
  </v-btn>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      'me',
      'role',
      'model',
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>